<template>
  <v-container :key="reloadKey">
    <v-row class="mt-4">
      <h3>Import Files</h3>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          id="tmc-import-fy"
          v-model.number="fiscalYear"
          label="Fiscal Year"
          type="number"
          @input="$v.fiscalYear.$touch()"
          @blur="$v.fiscalYear.$touch()"
          @change="determineTransactionNumbers"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <ClarionDateControl
          v-model="register"
          :isRequired="true"
          :isIsoDate="true"
          :label="`Register Date`"
          classes="tmc-import-reg-dt"
          @input="$v.register.$touch()"
          @blur="$v.register.$touch()"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="type"
          label="Transaction Type"
          type="text"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="status"
          label="Status"
          type="text"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          id="tmc-import-dept"
          v-model="department"
          :items="departments"
          item-text="caption"
          item-value="id"
          label="Department"
          @change="determineTransactionNumbers"
          @input="$v.department.$touch()"
          @blur="$v.department.$touch()"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="registration"
          label="Current Registration"
          type="text"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="voucher"
          label="Current Voucher"
          type="text"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-file-input id="tmc-import-file-upload"
          :disabled="!isValid"
          v-model="importClearableModel"
          accept=".txt,.csv"
          label="Import (.txt,.csv)"
          show-size
          clearable
          @change="onFileSelected"
        >
        </v-file-input>
      </v-col>
      <v-col>
        <v-chip
          v-if="isImported"
          class="ma-2 elevation-8"
          color="primary"
          @click.stop="openReport"
        >
          <v-icon>{{icons.mdiNote}}</v-icon>
          <span class="ml-4">View Voucher Register</span>
        </v-chip>
      </v-col>
    </v-row>
    <v-row v-if="importing">
      <v-col cols="2">
        <p id="tmc-status-bar">{{statusText}}</p>
      </v-col>
      <v-col cols="2">
        <p>{{upsertedCount}} records...</p>
      </v-col>
      <v-col cols="8">
        <v-progress-linear
          color="deep-purple accent-4"
          :value="progress"
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="reload">
      <v-col>
        <v-btn id="tmc-btn-import-state-reload"
          rounded @click="reloadMe">
          <v-icon class="mr-2">{{icons.mdiReload}}</v-icon> Load another
        </v-btn>
        <span> Imported in: {{this.timing}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  mdiReload,
  mdiNote,
} from '@mdi/js';

import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import { clients } from '../../util/clients';
import { getRawJwt } from '../../util/jwt';
import ClarionDateControl from '../../components/common/ClarionDateControl.vue';
import {
  dateDiffStr,
  toIsoDate,
  toFiscalYear,
} from '../../util/shared/tmc-global';

const { backendRest } = clients.direct;
const initdata = () => ({
  icons: {
    mdiReload,
    mdiNote,
  },
  importing: false,
  reload: false,
  reloadKey: 0,
  importClearableModel: undefined,
  statusText: '',
  progress: 0,
  upsertedCount: 0,
  timing: undefined,
  fiscalYear: undefined,
  register: '',
  department: undefined,
  status: 'OUTSTANDING',
  type: 'VOUCHER',
  voucher: undefined,
  registration: undefined,
  isImported: false,
  logs: '',
});

const isKnownLineEnding = (lastOne) => {
  const lastOneTrim = lastOne.replace(' ', '');
  const endsWithStateZip = lastOneTrim.match(/["]?[a-zA-Z]{2}["]?,["]?[0-9-]{5,10}["]?/) !== null;
  const endsWithNoAddr = lastOneTrim.match(/[,]{4}/) !== null;
  const endsWithQuotedNoAddr = lastOneTrim.match(/[",]{4}/) !== null;
  return (endsWithStateZip || endsWithNoAddr || endsWithQuotedNoAddr);
};

// we can be this specific on the state system page.
// Need something more generic for other CSV solutions.
const ensureCompleteLines = (lines) => {
  const filteredLines = lines
    .filter((l) => l && l.length > 12) // filter ',,,,,,,,,' types of lines
    .reduce((acc, curr) => {
      // state files sometimes have weird, unnecessary carriage returns in them
      const cleanCurr = curr.replace(/\r/g, '');

      if (acc.length <= 1) {
        // just take the header, and the first row w/o question
        return [...acc, cleanCurr];
      }
      const lastOne = acc[acc.length - 1];
      // did the previous line end with something like 'OK,12345' ?
      if (isKnownLineEnding(lastOne)) {
        // keep going (start a new one)
        acc.push(cleanCurr);
        return acc;
      }
      // append to the previous
      return [...acc.slice(0, -1), (lastOne + cleanCurr)];
    }, []);
  const lastOne = filteredLines[filteredLines.length - 1];
  if (!isKnownLineEnding(lastOne)) {
    throw new Error('Corrupt .csv file. Check that the last row is valid.');
  }
  return filteredLines;
};

const loadFile = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    let allLines = event.target.result;
    // remove \r (if Windows), split by \n for all OS's
    allLines = allLines.replace('\r', '');
    const lines = allLines.split('\n');
    const completeLines = ensureCompleteLines(lines);
    // console.log(JSON.stringify(completeLines, null, 1));
    resolve(completeLines);
  };
  reader.onend = (err) => {
    if (err) {
      reject(err);
    }
  };
  reader.onabort = (err) => {
    if (err) {
      reject(err);
    }
  };
  reader.readAsText(file, 'utf8');
});

export default {
  name: 'ImportFiles',
  mixins: [validationMixin],
  validations: {
    fiscalYear: { required },
    register: { required },
    department: { required },
  },
  data: () => ({
    ...initdata(),
    deptList: {},
  }),
  components: {
    ClarionDateControl,
  },
  computed: {
    ...mapState({
      registerRange: (state) => state.OD.registerRange,
    }),
    departments() {
      return this.$store.state.OD.departments;
    },
    nextRegistrationNumber() {
      return this.$store.state.OD.nextRegistrationNumber;
    },
    nextVoucherNumber() {
      return this.$store.state.OD.nextVoucherNumber;
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
  },
  created() {
    this.$store.dispatch('OD/loadDepartments').then(() => {
      const depts = this.$store.state.OD.departments;
      depts.forEach((e) => {
        this.deptList[e.id] = e;
      });
    });
    this.determineRegistrationDate();
  },
  methods: {
    ...mapActions('jobs', [
      'backup',
    ]),
    ...mapMutations('jobs', [
      'setPrefix',
      'setGroup',
    ]),
    ...mapActions('Bulk', [
      'upsertODTransactions',
    ]),
    ...mapActions([
      'flashError',
      'flashSuccess',
    ]),
    async determineRegistrationDate() {
      try {
        await this.$store.dispatch('OD/loadRegisterRange');
        this.register = new Date(this.registerRange.max) > new Date()
          ? this.registerRange.max : toIsoDate(new Date());
        this.fiscalYear = toFiscalYear(new Date(this.register));
      } catch (e) {
        console.warn(e);
        this.register = toIsoDate(new Date());
        this.fiscalYear = toFiscalYear(new Date());
      }
    },
    determineTransactionNumbers() {
      if (this.department) {
        const loadStatsPromise = this.$store.dispatch(
          'OD/loadTransStats',
          {
            type: this.type,
            department: this.department,
            fiscalYear: this.fiscalYear,
          },
        );
        loadStatsPromise.then(() => {
          this.voucher = this.nextVoucherNumber ? this.nextVoucherNumber - 1 : 0;
          this.registration = this.nextRegistrationNumber ? this.nextRegistrationNumber - 1 : 0;
        });
      }
    },
    openReport() {
      const report = 'voucherregister';
      const queryString = `?fromDate=${this.register}&toDate=${this.register}&departments=${this.department}&token=${getRawJwt()}`;
      const toUrl = `${backendRest.defaults.baseURL}/${report}${queryString}`;
      window.open(toUrl, '_blank');
    },
    async onFileSelected(file) {
      if (file) {
        this.timing = new Date();
        const self = this;
        this.importing = true;
        this.statusText = 'Loading file...';

        let successfulBackup = false;
        if (window.confirm('Would you like to take a backup before bulk changes?')) {
          this.setGroup('tmc-local-backend');
          this.setPrefix('statesystemprebak');
          successfulBackup = await this.backup();
        }
        if (!successfulBackup) {
          this.importing = false;
          this.statusText = '';
          this.importClearableModel = null;
          return false;
        }

        const transformUrl = '/transform?type=csv-parser-pipeline&template=statesystemvouchers';

        let allLines = await loadFile(file);
        let [header] = allLines;
        header += '\r\n';
        allLines = allLines.slice(1);

        let chunkId = 0;

        let i;
        let j;
        let lines;
        const batchesOf = 30;
        for (i = 0, j = allLines.length; i < j; i += batchesOf) {
          lines = allLines.slice(i, i + batchesOf);

          chunkId += 1;
          const recordsThusFar = i + lines.length;
          const percentComplete = Math.floor((recordsThusFar / allLines.length) * 100);
          self.statusText = `Importing records (${percentComplete}%) ...`;
          self.progress = percentComplete;

          let response;
          try {
            // we can be this specific on the state system page.
            // Need something more generic for other CSV solutions.
            // TODO - necessary ? .filter((l) => l && l.indexOf(',,,,,,,,') === -1);
            const filteredLines = lines;
            const payload = `${header}\r\n${filteredLines.join('\r\n')}`;
            response = await backendRest.post(
              transformUrl, payload,
            );
          } catch (error) {
            return clients.handleAxiosErrors(error);
          }

          let responseData = response.data;
          if (typeof responseData !== 'string') {
            // when only one line is imported, it comes back as an obj
            responseData = JSON.stringify(responseData);
          }
          // skip empty objects
          const upserts = JSON.parse(`[${responseData}]`)
            .filter((upsert) => Object.keys(upsert).length > 0);

          if (chunkId === 1) {
            const firstRecord = upserts[0];
            let alertMsg;
            let isConfirm = true;
            if (firstRecord && firstRecord.voucher <= self.voucher) {
              alertMsg = `Department '${self.deptList[`${self.department}`].caption}' last voucher
              number is ${self.voucher} but first record imported contains
              voucher number ${upserts[0].voucher}.
                Do you still want to proceed?`;
              isConfirm = window.confirm(alertMsg);
              if (!isConfirm) {
                self.flashError('Import Cancelled!');
                self.reload = true;
                return false;
              }
            }
          }

          // mapping data given by user
          upserts.forEach((_, k) => {
            upserts[k].type = self.type;
            upserts[k].registration = undefined; // choose these server-side
            upserts[k].register = self.register;
            upserts[k].fiscalYear = self.fiscalYear;
            upserts[k].deptNumber = self.deptList[`${self.department}`].dept;
            upserts[k].status = self.status;
          });

          // await new Promise((r) => setTimeout(r, 800));

          const upsertResponse = await self.upsertODTransactions(upserts, chunkId);
          // const upsertResponse = { ok: 1, upsertedCount: 10 };
          const { ok } = upsertResponse.data.upsertODTransactions.result;
          // const { ok } = upsertResponse;
          const { upsertedCount } = upsertResponse.data.upsertODTransactions;
          // const { upsertedCount } = upsertResponse;
          self.upsertedCount += upsertedCount;
          if (!ok) {
            throw new Error('Something errored during the import.');
          }
        }
        const startDt = self.timing;
        const endDt = new Date();
        self.timing = dateDiffStr(startDt, endDt);
        self.flashSuccess('Imported!');
        self.reload = true;
        self.isImported = true;
        self.openReport();
      }
      return true;
    },
    reloadMe() {
      window.location.reload();
    },
  },
};
</script>
